import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { addCoordinatesToImagePath } from '@/utils/common';
interface RecommendedProps {
  data: any;
}
const imageBaseUrl = process.env.NEXT_PUBLIC_IMAGE_BASE_URL;
const Recommended: React.FC<RecommendedProps> = ({ data: recommendedData }) => {
  const [last, setLastData] = useState<any>();
  const [recentData, setRecentData] = useState<any[]>([]);
  useEffect(() => {
    if (localStorage.categoryAndProduct) {
      const items: any[] = JSON.parse(localStorage.categoryAndProduct);
      if (items.length > 1) {
        const data: any = recommendedData?.items;
        items.forEach((item) => {
          item.imgSrc = `${imageBaseUrl}${addCoordinatesToImagePath(item.imgSrc, 750, 650)}`;
          if (item.href?.includes('/')) {
            const arr = item.href?.split('/');
            item.href = arr?.[arr.length - 1];
            data.unshift(item);
          }
        });
        const last = data?.shift();
        setLastData(last);
        const red = data?.splice(0, 4);
        setRecentData(red);
      }
    }
  }, []);
  return (
    <>
      {recentData?.length > 0 && (
        <section className={styles.recommendedsection}>
          <div className={styles.recommendedinner}>
            <div className="container-1600">
              <div className="wdRow ">
                <div className="wdCol-lg-12">
                  <div className={styles.recommendedheading}>
                    <h2>{recommendedData.title}</h2>
                    <p>{recommendedData.subtitle}</p>
                  </div>
                </div>
              </div>
              <div className={styles.recommendedcontent}>
                <div className="wdRow ">
                  {last && (
                    <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-12 wdCol-12">
                      <Link
                        href={
                          last.href?.replace(
                            'https://www.woodenstreet.com/',
                            '/'
                          ) ?? '#'
                        }
                        className={`${styles.recomcard} ${styles.recomcardbig}`}
                      >
                        <div
                          className={`${styles.recomimg} ${styles.recomimgbig}`}
                        >
                          <Image
                            src={last.imgSrc}
                            alt="best furniture online | best online furniture sites in india"
                            width={500}
                            height={500}
                            quality={100}
                            loading="lazy"
                          />
                          <span className={styles.recombtnover}>
                            <Image
                              width={103}
                              height={28}
                              src="/images/category-new/tag.svg"
                              alt="tag"
                              loading="lazy"
                              quality={100}
                            />
                            <label>{last.discountPercentage}</label>
                          </span>
                        </div>
                        <div className={styles.recomtext}>
                          <p>{last.title}</p>
                        </div>
                      </Link>
                    </div>
                  )}
                  <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-12 wdCol-12">
                    <div className="wdRow wdgx-md-3 wdgx-2">
                      {recentData?.map((item, index) => (
                        <div
                          className="wdCol-lg-6 wdCol-md-6 wdCol-sm-6 wdCol-6"
                          key={index}
                        >
                          <Link href={item.href} className={styles.recomcard}>
                            <div className={styles.recomimg}>
                              <Image
                                width={378}
                                height={327}
                                src={item.imgSrc}
                                alt="best furniture online | best online furniture sites in india"
                                title={item.title}
                                loading="lazy"
                                quality={100}
                              />
                              <span className={styles.recombtnover}>
                                <Image
                                  width={103}
                                  height={28}
                                  src="/images/category-new/tag.svg"
                                  alt="tag"
                                  loading="lazy"
                                  quality={100}
                                />
                                <label>{item.discountPercentage}</label>
                              </span>
                            </div>
                            <div className={styles.recomtext}>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Recommended;
